import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import styles from "./Common.module.scss";

const Payment = () => (
  <div className={styles.Area}>
    <div className={styles.Header}>
      <Link to="/">
        <FaArrowLeft />
      </Link>
      <span>Payment</span>
    </div>
    <div className={styles.Content}>
      <h3>Make a payment</h3>
      <span>
        Have you already contacted us and agreed upon a quote and service? Use
        the payment form below to clear your invoice or get started.
      </span>
    </div>
  </div>
);

export default Payment;
