import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import styles from "./Common.module.scss";

const About = () => (
  <div className={styles.Area}>
    <div className={styles.Header}>
      <Link to="/">
        <FaArrowLeft />
      </Link>
      <span>About</span>
    </div>
    <div className={styles.Content}>
      <h3>Who are we?</h3>
      <span>
        Lingolink is a translation services provider with our main office based
        in London, UK. We translate and typeset documents for a variety of
        sectors including legal, advisory and technical.
      </span>
      <br />
      <br />
      <span>
        Our staff are highly experienced in providing a quality language service
        to individuals or organisations in the UK.
      </span>
      <br />
      <br />
      <span>
        We want to be recognized as one of the UK’s easiest language service
        provider and to continue to develop our service so that we can provide
        environmentally friendly approaches. Let us take care of your language
        needs.
      </span>
    </div>
  </div>
);

export default About;
