import React from "react";
import { Link } from "react-router-dom";
import styles from "./Home.module.scss";
import { ReactComponent as Logo } from "./logo.svg";

const Home = () => (
  <div className={styles.Home}>
    <div className={styles.Title}>
      <Logo />
      <span>Lingolink</span>
    </div>
    <div className={styles.Content}>
      <div className={styles.Introduction}>
        <span>
          A professional translations company based in London, UK.
        </span>
        <span>
          Confidential, straight forward, and efficient.
        </span>
      </div>
      <div className={styles.Links}>
        <ul>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          {/* <li>
            <Link to="/payment">Payment</Link>
          </li> */}
        </ul>
      </div>
    </div>
    <div className={styles.Footer}>
      <span>© Copyright Lingolink UK ltd | lingolink.co.uk 2019</span>
    </div>
  </div>
);

export default Home;
