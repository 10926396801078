import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import styles from "./Common.module.scss";

const Services = () => (
  <div className={styles.Area}>
    <div className={styles.Header}>
      <Link to="/">
        <FaArrowLeft />
      </Link>
      <span>Services</span>
    </div>
    <div className={styles.Content}>
      <h3>Document translation</h3>
      <span>
        We translate a wide variety of documents ranging from contracts, legal
        defence, marketing and more.
      </span>
      <h3>Technical translation</h3>
      <span>
        For true consistency with all translations, we hand pick from our highly
        qualified linguists to ensure that your sector terminology and context
        is understood.
      </span>
      <h3>Interpreting</h3>
      <span>
        We’ve got talent, skills and resources to support you on a huge range of
        situations from conferences to court proceedings.
      </span>
      <br />
      <br />
      <span>
        In addition to the above we offer a variety of services that can be
        tailored to suit your needs, feel free to get in contact
      </span>
    </div>
  </div>
);

export default Services;
