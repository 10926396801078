import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import styles from "./Common.module.scss";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      type: "translations",
      message: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className={styles.Area}>
        <div className={styles.Header}>
          <Link to="/">
            <FaArrowLeft />
          </Link>
          <span>Contact</span>
        </div>
        <div className={styles.Content}>
          <h3>Request a quote</h3>
          <form onSubmit={this.handleSubmit}>
            <label>
              Name:
              <input
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Your Email:
              <input
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Quote for:
              <select
                name="type"
                value={this.state.type}
                onChange={this.handleChange}
              >
                <option value="translations">Translations</option>
                <option value="interpreting">Interpreting</option>
                <option value="telephone">Telephone Interpreting</option>
                <option value="multiple">Multiple Services</option>
              </select>
            </label>
            <label>
              Message:
              <textarea
                name="message"
                value={this.state.message}
                onChange={this.handleChange}
              />
            </label>
            <button type="submit" value="Submit">
              Send
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Contact;
